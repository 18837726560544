import React from 'react';

const PhpMyAdmin = () => {
    return (
        <div>
            <h2>phpMyAdmin Details</h2>
            <p>Example Data Coming Soon For phpMyAdmin...</p>
        </div>
    );
};

export default PhpMyAdmin;
