import React from 'react';

const Digital_Marketing = () => {
    return (
        <div>
            <h2>Digital_Marketing Details</h2>
            <p>Example Data Coming Soon For Digital_Marketing...</p>
        </div>
    );
};

export default Digital_Marketing;
