import React from 'react';

const Linux_Admin = () => {
    return (
        <div>
            <h2>Linux_Admin Details</h2>
            <p>Example Data Coming Soon For Linux_Admin...</p>
        </div>
    );
};

export default Linux_Admin;
