import React from 'react';

const ServiceNow = () => {
    return (
        <div>
            <h2>ServiceNow Details</h2>
            <p>Example Data Coming Soon For ServiceNow...</p>
        </div>
    );
};

export default ServiceNow;
