import React from 'react';

const Data_Analytics = () => {
    return (
        <div>
            <h2>Data_Analytics Details</h2>
            <p>Example Data Coming Soon For Data_Analytics...</p>
        </div>
    );
};

export default Data_Analytics;
