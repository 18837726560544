import React from 'react';

const ReactComponent = () => {
    return (
        <div>
            <h2>React Details</h2>
            <p>Example Data Coming Soon For React...</p>
        </div>
    );
};

export default ReactComponent;
