import React from 'react';

const LivePerson = () => {
    return (
        <div>
            <h2>LivePerson Details</h2>
            <p>Example Data Coming Soon For LivePerson...</p>
        </div>
    );
};

export default LivePerson;
