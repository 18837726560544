import React from 'react';

const CPanel = () => {
    return (
        <div>
            <h2>cPanel Details</h2>
            <p>Example Data Coming Soon For cPanel...</p>
        </div>
    );
};

export default CPanel;
