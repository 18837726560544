import React from 'react';

const Node = () => {
    return (
        <div>
            <h2>Node Details</h2>
            <p>Example Data Coming Soon For Node...</p>
        </div>
    );
};

export default Node;
