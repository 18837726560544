import React from 'react';

const Plesk = () => {
    return (
        <div>
            <h2>Plesk Details</h2>
            <p>Example Data Coming Soon For Plesk...</p>
        </div>
    );
};

export default Plesk;
