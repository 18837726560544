import React from 'react';

const Linux = () => {
    return (
        <div>
            <h2>Linux Details</h2>
            <p>Example Data Coming Soon For Linux...</p>
        </div>
    );
};

export default Linux;
