import React from 'react';

const MySQL = () => {
    return (
        <div>
            <h2>MySQL Details</h2>
            <p>Example Data Coming Soon For MySQL...</p>
        </div>
    );
};

export default MySQL;
