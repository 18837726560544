import React from 'react';

const Online_Advertising = () => {
    return (
        <div>
            <h2>Online_Advertising Details</h2>
            <p>Example Data Coming Soon For Online_Advertising...</p>
        </div>
    );
};

export default Online_Advertising;
