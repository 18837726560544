import React from 'react';

const Grafana = () => {
    return (
        <div>
            <h2>Grafana Details</h2>
            <p>Example Data Coming Soon For Grafana...</p>
        </div>
    );
};

export default Grafana;
